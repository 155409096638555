.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-check {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-empty {
	font-size:(45.437/10)*1rem;
	width:(45.437/45.437)*1em;
}
