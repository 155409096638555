//------------------------------------------------------------layout
.header {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 100%;
	will-change: transform;

	@include z-index(header);
}

//------------------------------------------------------------layout###