//------------------------------------------------------------base styles
html {
	height: 100%;
	font: 400 10px/1.33 $font_1;

	@include bp($point_2) {
		font: 400 1.2vw/1.33 $font_1;
	}
	@include bp($point_3) {
		font: 400 1.5vw/1.33 $font_1;
	}
	@include bp($point_4) {
		font: 400 1.9vw/1.33 $font_1;
	}
	@include bp($point_5) {
		font: 400 2.6vw/1.33 $font_1;
	}
}

body {
	position: relative;
	height: 100%;
	font-size: $font_size_base;
	color: $color_text_base;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $body_bg;
}

.wrapper {
	position: relative;
	min-height: 100%;
	height: auto !important;
	overflow: hidden;
}

.base {
	position: relative;
	padding: $height_header 0 $height_footer;
}

a {
	text-decoration: none;
	color: $color_link_base;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.elements_list {
	padding: 3rem;
}

//------------------------------------------------------------base styles###