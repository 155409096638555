//------------------------------------------------------------section
.section {
	@extend %global_flex_block_row_wrap_center;

	margin: 0 auto 3.4rem;

	&.top_mod {
		position: relative;
		padding: 14rem 0 21rem;
		overflow: hidden;

		@include bp($point_2) {
			padding: 7rem 0 10rem;
		}
	}

	&.offset_1_mod {
		padding: 4.2rem 0 0;
	}

	&.offset_2_mod {
		margin-bottom: 13rem;

		@include bp($point_2) {
			margin-bottom: 6rem;
		}
	}

	&.offset_3_mod {
		margin-bottom: 7.5rem;

		@include bp($point_2) {
			margin-bottom: 3rem;
		}
	}
}

.section_in {
	@extend %global_flex_block_row_wrap_flex-start;

	width: 100%;
	max-width: $page_width;
	margin: 0 ($gutter / -2) 0;

	&.offset_bottom_mod {
		margin-bottom: -12.4rem;
		@include bp($point_2) {
			margin-bottom: 0;
		}
	}

	&.border_mod {
		position: relative;

		&:after {
			position: absolute;
			left: ($gutter / 2);
			bottom: 15rem;
			width: calc(100% - #{$gutter});
			height: .2rem;
			display: block;
			content: '';
			background-color: $black;
		}
		@include bp($point_2) {
			&:after {
				bottom:0;
			}
		}
	}
}

.section_title {
	margin: 0 0 3.8rem;
	font-size: 7.2rem;
	font-weight: 700;
	line-height: .96;

	@include bp($point_2) {
		font-size: 4rem;
	}

	&.small_mod {
		font-size: 4.8rem;
		@include bp($point_2) {
			font-size: 4rem;
		}
	}

	&.offset_1_mod {
		margin: 0 0 3rem;
	}

	&.offset_2_mod {
		margin: 0 0 8.4rem;
		@include bp($point_2) {
			margin: 0 0 3rem;
		}
	}

	&.col_7_mod {
		@include cols(7);
		@include bp($point_2) {
			text-align: center;
			@include cols(12);
		}
	}

	&.col_9_mod {
		@include cols(9);
		@include bp($point_2) {
			text-align: center;
			@include cols(12);
		}
	}
}

.section_desc {
	line-height: .94;

	&.white_mod {
		color: $white;
		font-family: $gotham_narrow;
		font-size: 3.6rem;
		text-transform: uppercase;

		@include cols(5);
		@include bp($point_2) {
			margin: 0 auto;
			text-align: center;
			text-shadow: .1rem .1rem .5rem $black;
			@include cols(8);
		}
		@include bp($point_3) {
			@include cols(12);
		}
	}

	&.bordered_mod {
		position: relative;
		top: -23rem;
		left: -4.6rem;
		width: 74.3rem;
		height: 24.8rem;
		padding: 3rem 11rem;
		display: inline-flex;
		align-items: center;
		font-size: 3.6rem;
		font-weight: 900;
		line-height: 1.2;
		box-shadow:
			0 0 3.4rem 0 rgba($black, .11),
			inset 0 0 3.4rem 0 rgba($black, .11);
		border: 2rem solid $white;

		@include bp($point_2) {
			position: static;
			padding: 0;
			margin: 0 auto 3rem;
			font-size: 3rem;
			justify-content: center;
			text-align: center;
		}
		@include bp($point_5) {
			font-size: 3rem;
		}
	}
}
//------------------------------------------------------------section###

.btn {
	@extend %btn_size_1;
}

.send_btn {
	@extend %tomato_btn_1;

	width: 100%;
}

//------------------------------------------------------------page_top
.page_title {
	font-size: 7.2rem;
	font-weight: 900;
	line-height: .94;

	@include cols(6);
	@include bp($point_2) {
		margin: 0 0 3rem;
		text-align: center;
		@include cols(12);
	}
	@include bp($point_5) {
		font-size: 5.5rem;
	}
}

.contacts {
	display: none;
	font-size: 1.2rem;
	font-weight: 900;
	line-height: 1.33;
	text-align: right;

	@include cols(6);
	@include bp($point_2) {
		text-align: center;
		@include cols(12);
	}
	@include bp($point_3) {
		font-size: 1.8rem;
	}
}

.contact_email {
	color: inherit;
	text-decoration: none;
}

.logo_wrap {
	text-align: center;

	@include cols(12);
}

.logo {
	z-index: 10;
	position: relative;
	width: 19.5rem;
	height: 19.5rem;
	margin: 11rem auto 16rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:after {
		z-index: -1;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		display: block;
		content: '';
		transform: rotate(-45deg);
		background-color: rgba($tomato, .9);
	}

	.logo_img {
		display: block;
		width: 100%;
		height: 5.4rem;
		margin: auto;
	}

	@include bp($point_2) {
		width: 15rem;
		height: 15rem;
		margin: 6rem auto 11rem;
	}
}
//------------------------------------------------------------page_top###
.stage_item {
	margin: 0 0 5.3rem;
	line-height: 1.5;

	&:last-of-type {
		margin: 0;
		@include bp($point_2) {
			margin-bottom: 5.3rem;
		}
	}
}

.img_wrap {
	position: relative;

	&.top_mod {
		z-index: -1;
		position: absolute;
		top: -5%;
		left: -5%;
		width: 110%;
		height: 110%;
	}
	&.v1_mod {
		width: 48.9rem;
		height: 81.6rem;

		@include bp($point_2) {
			display: none;
		}
	}
	&.v2_mod {
		position: relative;
		top: -6rem;
		right: -8.4rem;
		width: 36rem;
		height: 43.5rem;

		@include bp($point_2) {
			//position: static;
			top: 0;
			right: 0;
			width: 70%;
			height: 25rem;
			margin: 0 auto;
			order: 2;
		}
	}
	&.v3_mod {
		position: relative;
		left: -5.2rem;
		width: 36rem;
		height: 43.5rem;

		@include bp($point_2) {
			position: static;
			width: 70%;
			height: 25rem;
			margin: 2rem auto 2rem;
			order: 2;
		}
	}
	&.v4_mod {
		position: relative;
		left: -2rem;
		width: 68.2rem;
		height: 81rem;

		&:before {
			z-index: 10;
			position: absolute;
			top: 7.5rem;
			right: -7rem;
			width: 55.6rem;
			height: 36rem;
			display: block;
			content: '';
			border: solid 1.6rem $white;
			box-shadow: 0 0 3.4rem 0 rgba($black, .11),
			inset 0 0 3.4rem 0 rgba($black, .11);
		}

		@include bp($point_2) {
			display: none;
		}
	}
	&.v5_mod {
		margin: 0 0 0 ($gutter / 2);
		width: 58.5rem;
		height: 66rem;

		@include bp($point_2) {
			margin: 0 auto;
			width: 70%;
			height: 40rem;
			order: 2;
		}
	}
}

.img {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	box-shadow: 0 -.1rem 2.2rem 0 rgba($black, .4);

	&.no_shadow_mod {
		box-shadow: none;
	}

	&.cover_mod {
		position: static;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

//---------------------------------------------fade animation
.fadeTrigger {
	.img,
	&.v4_mod:before {
		@extend %transition_opacity;

		opacity: 0;
		will-change: transform;
	}
}

.fadeIn {
	.img,
	&.v4_mod:before {
		opacity: 1;
	}
}
//---------------------------------------------fade animation###

.strategy_col_left {
	padding: 0 10rem 0 ($gutter / 2);
	width: 50%;
	@include bp($point_2) {
		padding: 0;
		text-align: center;
		@include cols(12);
	}
}

.strategy_col_right {
	position: relative;
	left: 1.7rem;
	top: -14rem;
	@include bp($point_2) {
		text-align: center;
		@include cols(12);
	}
}

//------------------------------------------------------------work block
.work_wrap {
	width: 100%;
	@extend %global_flex_block_row_wrap_flex-start;

	&.offset_1_mod {
		margin: 0 0 2.6rem;
	}
	&.offset_2_mod {
		margin: 0 0 9rem;

		@include bp($point_2) {
			margin: 0 0 3rem;
		}
	}
	&.offset_3_mod {
		margin: 0 -1rem 0 1.5rem;
		@include bp($point_2) {
			margin: 0;
		}
	}
	&.bordered_mod {
		width: 94.6rem;
		margin: 0 auto;
		padding: 5.5rem 7rem 6.5rem;
		box-shadow: 0 0 1.7rem 0 rgba($black, .11);
		border: solid 1.5rem $tomato;

		@include bp($point_2) {
			padding: 3rem;
		}

		@include bp($point_5) {
			padding: 1rem;
		}
	}
}

.work_block {
	&.col_4_mod {
		@include cols(4);
		@include bp($point_2) {
			margin: 0 auto 3rem;
			@include cols(8);
		}
		@include bp($point_3) {
			@include cols(12);
		}
	}
	&.col_6_mod {
		&:nth-child(1),
		&:nth-child(2) {
			margin: 0 0 5rem;
		}

		@include cols(6);

		@include bp($point_3) {
			margin: 0 0 3rem;
			@include cols(12);

			&:nth-child(1),
			&:nth-child(2) {
				margin: 0 0 3rem;
			}
		}
	}
	&.offset_top_mod {
		margin-top: 2rem;
	}
	&.offset_bottom_mod {
		margin-bottom: 12rem;

		@include bp($point_2) {
			margin-bottom: 3rem;
		}
	}
}

.work_col {
	padding: 0 3rem 0 7rem;
	width: 41%;
	border-left: .1rem solid $gray_1;

	@include bp($point_2) {
		margin: 0 auto 3rem;
		border: none;
		@include cols(8);
	}
	@include bp($point_3) {
		@include cols(12);
	}
}

.work_b_title {
	width: 100%;
	position: relative;
	min-height: 6.8rem;
	margin: 0 0 1.2rem;
	font-size: 3.6rem;
	font-weight: 700;
	line-height: .94;

	span {
		position: absolute;
		bottom: 0;
	}


	@include bp($point_2) {
		display: block;
		min-height: 0;
		font-size: 3rem;
		text-align: center;
	}

	&.tomato_mod {
		color: $tomato;
		text-transform: uppercase;

		@include bp($point_2) {
			text-align: left;
		}
	}
}

.work_b_desc {
	margin: 0 0 1rem;
	line-height: 1.75;

	p {
		margin: 0 0 1rem;
		&:last-of-type {
			margin: 0;
		}
	}
}
//------------------------------------------------------------work block###

.feedabck_form {
	padding-left: 9.8rem;
	width: 47.5%;
	flex: 0 0 auto;

	@include bp($point_2) {
		margin: 0 auto 3rem;
		@include cols(8);
	}
	@include bp($point_3) {
		@include cols(10)
	}
	@include bp($point_5) {
		@include cols(12);
	}
}

.form_desc {
	margin: 0 0 5rem;
	font-size: 3.6rem;
	font-weight: 700;
	line-height: .96;

	@include bp($point_2) {
		margin: 0 0 3rem;
		font-size: 3rem;
		text-align: center;
	}
}