@mixin st_btn() {
	display: inline-block;
	white-space: nowrap;
	cursor: pointer;
	font-family: $font_1;

	&:hover, &:focus {
		text-decoration: none;
	}
}

//color extends
%tomato_btn_1 {
	color: $white;
	font-weight: 700;
	background: $tomato;
	transition: .3s ease;

	@include st_btn();

	&:hover {
		background: darken($tomato, 10%);
	}
}

//size extends
%btn_size_1 {
	height: 6rem;
	padding: 0 1.2rem;
	line-height: 6rem;
	font-size: 2.4rem;
}