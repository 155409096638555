//---------------------------------------------fonts
$font_path: "../fonts/";
$gotham: 'Gotham', sans-serif;
$gotham_narrow: 'GothamNarrow', sans-serif;
$gotham_black: 'GothamBlack', sans-serif;

$font_1: $gotham;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$gray_1: #979797;
$gray_2: #9b9b9b;
$tomato: #ed4527;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 0;
$height_header: 0;
$section_width: 80rem;
$page_width: 117rem;
//---------------------------------------------layout###

//---------------------------------------------grid
$cols: 12;
$gutter: 3rem;
//---------------------------------------------grid

//---------------------------------------------global values
$gl_indent: 3rem;
$gl_radius:.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 768px;
$point_4: 480px;
$point_5: 320px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 6rem;
$input_offset: 0 2rem .2rem;
$input_font_size: $font_size_base;
$input_border_color: $gray_1;
$input_border_color_active: darken($input_border_color, 10%);
$input_placeholder_color: $gray_2;
$input_text_color: $black;
$input_radius: 0;
//---------------------------------------------forms###